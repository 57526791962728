@import './../../styles/import-all.scss';

.title-wrapper {
  width: 100%;
  background-color: $color-white;
  padding: $spacing-s 0 $spacing-ml 0;

  @include spacing('padding-top', $space-settings-s);
  @include spacing('padding-bottom', $space-settings-ml);
}
