@import './../../styles/import-all.scss';

.title-h1 {
	text-align: center;
	@include content-width();
	@include spacing-fake-margin-bottom($space-settings-l);
	@include spacing-fake-margin-bottom($space-settings-m, '.searchbar');
	@include spacing-fake-margin-bottom($space-settings-s, '.lead');
	@include spacing-fake-margin-bottom($space-settings-xs, '.tags');
	@include fake-margin-bottom(0, '.section-container');


	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
	white-space: pre-wrap;
}

.title-h1--left {
	text-align: left;
}

.title-h1--highlight {
	color: $color-primary;
}

.content > .title-h1:first-child {
	@include spacing('margin-top', $space-settings-s);
}
