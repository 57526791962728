@import './../../styles/import-all.scss';

.section {
  width: 100%;
}

.section--swiper {
  @include flexbox(column, wrap, center, center);
}

.swiper__container {
  width: 100%;
  transition: opacity 0.6s ease;
}

.swiper__slide {
  @include content-width(false);
}

.swiper__title {
  text-align: center
}

.swiper__image-wrapper {
  @include responsive-ratio(100%, 91, 51, 'img');
  @include spacing-fake-margin-bottom($space-settings-s);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-white;
}

.swiper__image-wrapper.swiper__image-wrapper--boarder {
  border: 5px solid var(--color);
}

.swiper__svg {
  width: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  .bg {
    fill: transparent;
  }
}

img.swiper__image {
  height: auto;
  top: 50%;
  transform: translateY(-50%);
}

.swiper__image-link {
  width: 100%;
  height: 80vh;
  position: absolute;
  top: 0;
  left: 0;

  .swiper-slide-next &,
  .swiper-slide-prev &{
    height: 0;
    width: 0;
  }
}

.swiper-slide-next,
.swiper-slide-prev {
  z-index: 5 !important;
}

.swiper-slide-next {
  @include sm-max() {
    transform: translate3d(0px, 0px, -500px) rotateX(0deg) rotateY(0deg) scale(1) !important;
  }
}

.swiper-slide-prev {
  @include sm-max() {
    transform: translate3d(0px, 0px, -500px) rotateX(0deg) rotateY(0deg) scale(1) !important;
  }
}

.swiper__anchor {
  display: inline-block;

  &:hover,
  &:focus {
    color: $color-primary;
  }
}
