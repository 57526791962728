@import './../../styles/import-all.scss';

.tags {
  @include content-width();
  @include flexbox(row, wrap, center, flex-start);
	@include spacing-fake-margin-bottom($space-settings-l);
}

.tags__item {
  margin-right: 1em;
  @include fluid-font-size($font-size-info-s, $font-size-info-m);

  &::before {
    content: '#';
    margin-right: 0.5rem;
    color: $color-primary;
  }
}

.tags__link {
  &:hover,
  &:focus {
    color: $color-primary;
  }
}
