@mixin media-breakpoint-up($name, $breakpoints: $layout-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $layout-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-between(
  $lower,
  $upper,
  $breakpoints: $layout-breakpoints
) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

@mixin media-breakpoint-only($name, $breakpoints: $layout-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}

@mixin xs-min() {
  @include media-breakpoint-up(xs) {
    @content;
  }
}

@mixin sm-min() {
  @include media-breakpoint-up(sm) {
    @content;
  }
}

@mixin md-min() {
  @include media-breakpoint-up(md) {
    @content;
  }
}

@mixin lg-min() {
  @include media-breakpoint-up(lg) {
    @content;
  }
}

@mixin xl-min() {
  @include media-breakpoint-up(xl) {
    @content;
  }
}

@mixin xs-max() {
  @include media-breakpoint-down(xs) {
    @content;
  }
}

@mixin sm-max() {
  @include media-breakpoint-down(sm) {
    @content;
  }
}

@mixin md-max() {
  @include media-breakpoint-down(md) {
    @content;
  }
}

@mixin lg-max() {
  @include media-breakpoint-down(lg) {
    @content;
  }
}

@mixin between($min: null, $max: null) {
  @include media-breakpoint-between($min, $max) {
    @content;
  }
}

@mixin media-breakpoint-up($name, $breakpoints: $layout-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $layout-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-between(
  $lower,
  $upper,
  $breakpoints: $layout-breakpoints
) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

@mixin media-breakpoint-only($name, $breakpoints: $layout-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}

@mixin layout-breakpoint() {
  @media (min-width: $layout-breakpoint) {
    @content;
  }
}
