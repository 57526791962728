@import './../../styles/import-all.scss';

.loading-box{
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $color-primary;
}
