@import './../../styles/import-all.scss';

.tiles {
	@include content-width(true);
	@include fake-margin-bottom(2.5 * $gap-const);
	@include fake-margin-bottom(0, '.custom-cursor');
}

.tiles__title {
	@include font($type-face-serif, $font-weight-medium);
	@include spacing-fake-margin-bottom($space-settings-xs);
	width: 100%;
}

.tiles__item,
.tiles__item.title-h2 {
	@include grid(9);
	margin-bottom: 7%;

	@include md-min {
		@include grid(4.5);
		margin-bottom: 3.5%;
	}

	@include lg-min {
		@include grid(3);
	}
}

.tiles__image-wrapper {
	@include responsive-ratio(100%, 16, 9, 'img');
	@include responsive-ratio(100%, 16, 9, 'svg');

	&::after {
		content: '';
		width: 100%;
		height: 100%;
		background-color: transparent;
		position: absolute;
		left: 0;
		top: 0;
		transition: background 0.5s ease;

		.tiles__item:hover &,
		.tiles__item:focus &,
		.tiles__item:focus-visible & {
			background-color: rgba($color-primary, 0.3);
			transition: background 0.25s ease;
		}
	}
}

.tiles__img {
	object-fit: cover;
}

.tiles__svg {
	border: 5px solid var(--color);
}

.ice__path {
	fill: var(--color);
}

.tiles__item-inner {
	padding: 1rem 0;

	@include lg-min {
		padding: 1.5rem 0rem 4rem;
	}
}

.tiles__button {
	@include flexbox(row, wrap, flex-start, center);
	cursor: pointer;
}

.tiles__icon-smile {
	@include size(2.5rem);
	margin-right: 1rem;
}

.tiles__anchor {
	width: 100%;
	display: inline-block;

	.tiles__item:hover &,
	.tiles__item:focus &,
	.tiles__item:focus-visible & {
		color: $color-primary;
	}
}

.tiles__item {
	.cls-1,
	.cls-2,
	.cls-3 {
		fill: none;
	}

	.cls-2 {
		stroke-linejoin: round;
	}

	.cls-2,
	.cls-4,
	.cls-3 {
		stroke: currentColor;
		stroke-width: 2px;
	}
}
