@import './../../styles/import-all.scss';

.burger-menu {
  display: flex;
  justify-content: flex-end;
  @include font-size($font-settings-small);
  cursor: pointer;
}

.burger-menu__lines {
  position: relative;
}

.burger-menu__line {
  width: 20px;
  height: 2px;
  background-color: $color-black;
  position: absolute;
  top: 4px;
  right: 0;
  margin-right: 5px;
  transform: rotate(0deg);
  transition: transform 0.25s ease;

  .burger-menu.burger-menu--open & {
    transform: rotate(45deg);
    top: 10px;
    width: 2rem;
    transition: transform 0.25s ease;
  }

  &.burger-menu__line-1 {
    .burger-menu--open & {
      transform: rotate(-45deg);
      width: 2rem;
      top: 10px;
      transition: all 0.25s ease;
    }
  }

  &.burger-menu__line-2 {
    opacity: 1;
    top: 18px;
    width: 15px;
    transform: rotate(0deg);
    transition: all 0.1s ease 0.05s;

    .burger-menu.burger-menu--open & {
      transform: rotate(45deg);
      width: 2rem;
      top: 10px;
    }
  }

  &.burger-menu__line-3 {
    width: 10px;
    top: 11px;
    transition: opacity 0.1s ease 0.05s;

    .burger-menu.burger-menu--open & {
      opacity: 0;
    }
  }
}

.burger-menu:hover .burger-menu__line,
.burger-menu:focus .burger-menu__line,
.burger-menu:focus-visible .burger-menu__line {
  width: 2rem;
}
