@import './../../styles/import-all.scss';

.logo {
  text-transform: uppercase;
  @include font-size($font-settings-logo);
  @include font($type-face, $font-weight-medium);

  svg {
    width: 1rem;
    height: auto;
    margin: 0 1rem;
    color: $color-primary;

    @include sm-min {
      width: 2.05rem;
    }
  }
}

.logo__link {
  display: inline-block;
  padding: 30px 0;
  @include flexbox(row, wrap, center, center);
  letter-spacing: 4.05px;
}
