@use 'sass:math';

@mixin grid-container($col-count: $col-count) {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  --columns: #{$col-count};
}

@mixin grid(
  $col: $col,
  $shift-count: 0,
  $col-count: $col-count,
  $gap-constant: $gap-const
) {
  --width: #{$col};
  --gap-constant: #{$gap-const};
  --initialbasis: calc(
    (var(--width, 0) / var(--columns, #{$col-count}) * 100%) - #{$gap-constant}
  );
  $gap-no-unit: strip-unit($gap-constant);
  $shift: math.div(
    100,
    $col-count * $shift-count + math.div($gap-no-unit, 2) * 1%
  );
  flex-basis: var(--initialbasis);
  margin-right: calc(#{$gap-constant} / 2);

  @if $shift-count > 0 {
    margin-left: $shift;
  } @else {
    margin-left: calc(#{$gap-constant} / 2);
  }
}
