@use 'sass:math';

@mixin responsive-ratio($cw: 100%, $rw: 16, $rh: 9, $c: 'iframe') {
  position: relative;
  width: $cw;
  height: 0;
  padding: (math.div($rh, $rw) * $cw) 0 0 0;
  display: block;

  @if $c != null {
    #{$c} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@mixin responsive-ratio-center($cw: 100%, $rw: 16, $rh: 9, $c: 'iframe') {
  position: relative;
  width: $cw;
  height: 0;
  padding: (math.div($rh, $rw) * $cw) 0 0 0;
  display: block;

  @if $c != null {
    #{$c} {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: auto;
      transform: translateY(-50%);
    }
  }
}

@mixin responsive-ratio-center-image($cw: 100%, $rw: 16, $rh: 9) {
  position: relative;
  width: $cw;
  height: 0;
  padding: (math.div($rh, $rw) * $cw) 0 0 0;
  display: block;

  &::after {
    background-color: rgba(30, 30, 30, 0.2);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-50%);
  }
}

@mixin responsive-ratio-pseudo($x, $y, $pseudo: false) {
  $padding: unquote(math.div($y, $x) * 100 + '%');
  @if $pseudo {
    &:before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }
}
