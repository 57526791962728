@import './../../styles/import-all.scss';

.tiles-round {
  @include content-width(true);
  @include fake-margin-bottom($spacing-s, '.title-h2');
}

.tiles-round__item {
  @include grid(9);
  @include flexbox(row, wrap, flex-start, center);
  @include fake-margin-bottom($spacing-xs);
  @include fluid-font-size($font-size-info-s, $font-size-info-m);

  @include md-min {
    @include grid(4.5);
  }
}

.tiles-round__image-wrapper {
  @include responsive-ratio(gridItemSizeInProcent(1, 4.5), 1, 1, 'img');
  margin-right: gridColSizeInProcent(1, 4.5); //100 / (1024 / 50) * 20
}

.tiles-round__image {
  border-radius: 50%;
  object-fit: cover;
}

.tiles-round__item-nr {
  font-weight: $font-weight-bold;
}
