@import './../../styles/import-all.scss';

.info-box {
  @include content-width();
  border: 2px solid $color-primary;
  padding: $spacing-35 2 * $spacing-xxs;
  @include fake-margin-bottom($spacing-6);
}

.info-box__title {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  background-color: $color-white;
  padding: $spacing-xxs * 2;
  color: $color-primary;
}

.info-box__list {
  column-gap: 1.17em;
  column-count: 1;

  @include sm-min {
    column-count: 2;
  }

  @include md-min {
    column-count: 3;
  }
}

.info-box__list-item {
	@include spacing-fake-margin-bottom($space-settings-xs);
}

.info-box__text-bold {
  font-weight: $font-weight-bold;
}

.info-box__text {
  @include fluid-font-size($font-size-info-s, $font-size-info-m);
}
