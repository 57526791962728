@import './../../styles/import-all.scss';

.loading-spinner{
  width: 8rem;
  height: 8rem;
  position: relative;
  margin: auto;

  &::before, &::after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
  }
  &::before{
    background-color: $color-primary;
    animation: pulse2 1s ease-in-out infinite;
  }
  &::after{
    background-color: $color-primary;
    animation: pulse2 1s 0.5s ease-in-out infinite;
  }
}

@keyframes pulse2{
  0%, 100%{
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  50%{
    transform: translate(-50%, -50%) scale(1.0);
    opacity: 0;
  }
}