@import './../../styles/import-all.scss';

.footer {
  @include flexbox(row, wrap, space-between, flex-start);
  width: 100%;
  flex-shrink: 0;
  background-color: $color-white;
  padding-top: $spacing-ml;
}

.footer__inner {
  padding: $gutter 0;
  width: 100%;
  background-color: $color-primary;
  color: $color-white;
}

.footer__wrap {
  @include flexbox(row, wrap, space-between, flex-start);
  @include full-width();
}

.footer__right {
  @include flexbox();
}

.footer-nav__wrapper {
  @include flexbox();
}

.footer-nav__item {
  margin-left: 30px;
}

.footer-nav__link {
  z-index: 1;
  color: $color-white;
  text-decoration: none;

  &:hover,
  &:focus,
  &.active {
    color: $color-white;
    text-decoration: underline;
  }
}
