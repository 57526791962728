@import './../../styles/import-all.scss';

.custom-cursor {
  display: none;
  position: fixed;
  top: -32px;
  left: -32px;
  z-index: 11000;
  pointer-events: none;
  user-select: none;
  cursor: none;
  color: $color-white;

  @include sm-min(){
    display: block;
  }

  svg {
    width: 60%;
    height: auto;
  }
}

.custom-cursor__eye,
.custom-cursor__slider {
  position: absolute;
  opacity: 0;
  transform: scale(0.5, 0.5);
  transition: all 0.15s ease-in;
  background-color: $color-primary;
  border-radius: 50%;
  @include size(6.4rem);
  @include flexbox(row, wrap, center, center);

  .is-visible & {
    transform: scale(1, 1);
    transition: all 0.5s ease-out;
  }
}

.custom-cursor__eye {
  .is-visible.eye & {
    opacity: 1;
  }
}

.custom-cursor__slider {
  .is-visible.slider & {
    opacity: 1;
  }
}

.custom-cursor__text {
  display: block;
  width: 100%;
  margin-bottom: $gap-in-px;
  text-align: center;
}
