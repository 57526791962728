@import './../../styles/import-all.scss';

.title-h2 {
  color: $color-primary;
  @include content-width();
  @include font($type-face-serif, $font-weight-medium);
  @include spacing-fake-margin-bottom($space-settings-xs-double);
  @include fake-margin-bottom(0, '.tiles__item');
}

.title-h2 + .portion-calculation {
  @include fake-margin-bottom(2 * $spacing-xxs);
}
