@import './../../styles/import-all.scss';

.header {
	width: 100%;
	flex-shrink: 0;
	z-index: 100;
	position: fixed;
}

.header__wrapper {
	width: 100%;
	background-color: $color-white;
	z-index: 100;
}

.header__inner {
	@include flexbox(row, wrap, space-between, center);
	@include full-width();
}

.header__right {
	justify-items: flex-end;
}

.header__center {
	margin-top: 0;
	@include flexbox(row, wrap, center, flex-start);
}
