@import './../../styles/import-all.scss';

.searchbar {
  @include content-width();
	@include spacing-fake-margin-bottom($space-settings-l);
  @include flexbox(row, wrap, center, flex-start);
}

.searchbar__searchfield-wrapper {
  width: 100%;

  @include md-min {
    width: 80%;
  }
}

input.searchbar__searchfield {
  width: 100%;
  border-radius: 10rem;
  padding: 1.5rem 2.5rem;
  @include fluid-font-size($font-size-info-s, $font-size-info-m);
  line-height: $line-height-info;
  border: 2px solid $color-primary;
  transition: background 0.5s ease;

  &:hover,
  &:focus-visible,
  &:focus,
  &:active {
    background-color: $color-primary;
    color: $color-white;
    transition: all 0.5s ease;
    outline: none;

    &::placeholder {
      --color-a: 0.75;
      color: $color-primary;
    }

    + .searchbar__icon-wrapper {
      color: $color-white;
    }
  }

  &::placeholder {
    color: rgba($color-black, 0.5);
  }
}

.searchbar__icon-wrapper {
  @include size(1.7rem);
  position: absolute;
  right: $gutter;
  top: 50%;
  transform: translateY(-50%);
  color: rgba($color-black, 0.5);
}

.searchbar__icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.searchbar__icon.close {
  right: 2.5rem;
  @include size(1.7rem);
}

.close__line {
  width: 100%;
  height: 2px;
  background-color: rgba($color-black, 0.5);
  display: inline-block;
  transform: rotate(45deg);
  position: absolute;
  top: 8px;
  right: 0px;
}

.close__line:last-child {
  transform: rotate(-45deg);
}
