@import './../../styles/import-all.scss';

.fullscreen-image {
  width: 100%;
  height: auto;

  @include md-min {
    height: 100vh;
  }

  &--center {
    background-color: $color-primary;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.fullscreen-image__image {
  position: relative;
  z-index: -1;
  width: 100%;

  @include md-min {
    left: 0px;
    bottom: 0px;
    right: 0px;
    transform: translateY(-50%);
    position: fixed;
    top: 50%;
  }
}

.fullscreen-image__svg {
  max-width: 100%;
  width: 500px;
  margin: auto;

  .bg {
    fill: $color-primary;
  }

  .ice__path {
    fill: $color-white;
  }
}