$color-black: #000000 !default;
$color-white: #ffffff !default;

$color-blacky: #282c29 !default;
$color-turkies: #50e3c2 !default;
$color-light-grey: #fafafa !default;
$color-red: #bb4646 !default;

$color-main-text: $color-black !default;
$color-negative-text: $color-white !default;
$color-primary: var(--color, $color-turkies) !default;
$color-highlight: $color-turkies !default;
