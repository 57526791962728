@import './../../styles/import-all.scss';

.main-nav {
	background-color: $color-white;
	max-width: 100%;
	transform: translateY(-30rem);
	max-height: 0;
	transition: all 0.25s ease-in-out;
	z-index: 10;

	@include sm-min() {
		@include full-width();
	}

	.header--open & {
		max-height: 100vh;
		transform: translateY(0);
		transition: all 0.25s ease-in-out;
	}
}

.main-nav__inner {
	padding: 35px 0 80px 20px;
	overflow-y: auto;
	max-height: 0;
	transition: all 0.25s ease-in-out;

	@include sm-min() {
		padding: 35px 0 80px 0;
	}

	@include lg-min() {
		padding: 35px 50px 80px;
	}

	.header--open & {
		max-height: 90vh;
		transition: all 0.25s ease-in-out;
	}
}

.main-nav__list-container {
	@include flexbox();
}

.main-nav__subtitle {
	@include fluid-font-size($font-size-h3-s, $font-size-h3-m);
	@include font($type-face, $font-weight-regular);
	margin-bottom: em(25px, 20);
	line-height: $line-height-h3;
}

.main-nav__list-item {
	margin-right: em(20px, 18);
	margin-bottom: em(10px, 18);
	&::before {
		content: '#';
		margin-right: 5px;
		color: $color-primary;
	}
}

.main-nav__list-link {
	@include fluid-font-size($font-size-info-s, $font-size-info-m);
	font-weight: $font-weight-regular;
	cursor: pointer;
	&:hover,
	&:focus,
	.main-nav__list-item.active & {
		color: $color-primary;
	}
}

.main-nav__column {
	flex: 0 1 50%;
	margin-bottom: em(35px, 20);
	padding-right: 20px;

	@include md-min() {
		flex: 0 1 25%;
	}
}
